import { useAuthContext } from "../../hooks/useAuthContext";
import useFetch from "../../hooks/useFetch";
import { useEffect, useState } from "react";
import { addDays, format } from "date-fns";
import axios from "axios";
import styled from "styled-components";

import { useSearchParams } from "react-router-dom";

export default function Admin() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { signer } = useAuthContext();
  const [delta, setDelta] = useState(0);

  const [id, setId] = useState(searchParams.get("id") || null);
  const [sessionId, setSessionId] = useState(
    searchParams.get("sessionId") || null
  );
  const [slot] = useState(null);

  const [studentName, setStudentName] = useState("");
  const [sessionName, setSessionName] = useState("");

  useEffect(() => {
    if (searchParams.get("id")) {
      if (sessionId) {
        setSearchParams({
          id: searchParams.get("id"),
          sessionId: sessionId,
        });
      } else {
        setSearchParams({ id: searchParams.get("id") });
      }

      setId(searchParams.get("id"));
    }

    if (searchParams.get("sessionId")) {
      setSearchParams({ sessionId: searchParams.get("sessionId") });

      if (id) {
        setSearchParams({
          sessionId: searchParams.get("sessionId"),
          id: id,
        });
      } else {
        setSearchParams({ sessionId: searchParams.get("sessionId") });
      }
      setSessionId(searchParams.get("sessionId"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const { data, isLoading, error } = useFetch(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/signatures?delta=${delta}&id=${id}&slot=${slot}&sessionId=${sessionId}`,
    {
      headers: {
        Authorization: `Bearer ${signer.token}`,
      },
    }
  );

  return (
    <AdminStyle className="main-container">
      {isLoading ? (
        <h1>Loading</h1>
      ) : error ? (
        <h1>{error}</h1>
      ) : (
        <>
          <div className="sticky">
            <div className="main-title">
              <button onClick={() => setDelta(delta - 1)}>⬅️ Précédent</button>
              <h1>
                Nous sommes le&nbsp;
                {format(
                  addDays(new Date(), delta),
                  "dd/MM/yyyy"
                ).toString()}{" "}
                /&nbsp;
                <span style={{ fontSize: "37px" }}>{data.length}</span>{" "}
                signatures !
              </h1>
              <button onClick={() => setDelta(delta + 1)}>Suivant ➡️</button>
            </div>

            <div className="option-list">
              <div className="left">
                {studentName ? (
                  <p>
                    Elève :{" "}
                    <span style={{ fontSize: "18px" }}>{studentName}</span>
                  </p>
                ) : (
                  <p>Pas d'élève sélectionné</p>
                )}
                {sessionName ? (
                  <p>
                    Session :{" "}
                    <span style={{ fontSize: "18px" }}>{sessionName}</span>{" "}
                  </p>
                ) : (
                  <p>Pas de session sélectionnée</p>
                )}
              </div>
              <div className="right">
                <button
                  onClick={() => {
                    searchParams.delete("id");
                    searchParams.delete("sessionId");
                    setSearchParams(searchParams);
                    setId(null);
                    setSessionId(null);

                    //
                    setSessionName("");
                    setStudentName("");
                  }}
                >
                  Reset
                </button>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setId(e.target.idInput.value);
                    console.log(e.target.idInput.value);
                  }}
                >
                  <input
                    type="text"
                    placeholder="signer id here"
                    name="idInput"
                    // onChange={(e) => setId(e.target.value)}
                    value={id}
                  />
                  <input type="submit" />
                </form>
              </div>
            </div>
          </div>

          <div className="grid">
            {data.map((signature, index) => {
              const indexPlus = index + 1;

              return (
                <div
                  style={{
                    background: indexPlus % 2 === 0 ? "lightgray" : "white",
                    color: !signature?.file && "red",
                  }}
                  className="flex-container"
                  key={signature?._id}
                >
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    {new Date(signature?.startAtUTC).toLocaleString()}
                    <div>
                      {signature?.openedDate ? (
                        <span
                          style={{
                            color: "green",
                          }}
                        >
                          Opened !{" "}
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "red",
                          }}
                        >
                          Closed
                        </span>
                      )}
                      &nbsp;
                      {signature?.signedDate ? (
                        <span
                          style={{
                            color: "green",
                          }}
                        >
                          Signed !{" "}
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "red",
                          }}
                        >
                          Unsigned !!
                        </span>
                      )}
                    </div>
                  </div>

                  {signature?.file ? (
                    <img
                      style={{
                        height: "50px",
                        width: "150px",
                        border: "1px solid grey",
                      }}
                      src={signature?.file}
                      alt=""
                    />
                  ) : (
                    <div
                      style={{
                        height: "50px",
                        width: "150px",
                        border: "1px solid grey",
                        background: "#ff7d7d",
                      }}
                    />
                  )}

                  <div
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "17px",
                    }}
                    onClick={() => {
                      setStudentName(
                        `${signature?.signerId?.firstName} ${signature?.signerId?.lastName}`
                      );
                      setSearchParams({ id: signature?.signerId?._id });
                    }}
                  >
                    <span>{signature?.signerId?.firstName} </span>
                    <span>{signature?.signerId?.lastName}</span>
                  </div>

                  <span
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      if (signature?.sessionId?.batch) {
                        setSessionName(signature?.sessionId?.batch);
                      } else if (signature?.sessionId?.name) {
                        setSessionName(signature?.sessionId?.name);
                      } else {
                        setSessionName("Session sans nom !");
                      }
                      setSearchParams({
                        sessionId: signature?.sessionId?._id,
                      });
                    }}
                  >
                    {signature?.sessionId?.batch}
                  </span>
                  <span>{signature?.sessionId?.name}</span>

                  <button
                    onClick={async () => {
                      try {
                        await axios.post(
                          `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/signatures/delete-signature`,
                          {
                            signatureId: signature._id,
                            sessionId: signature.sessionId._id,
                          },
                          {
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${signer.token}`,
                            },
                          }
                        );

                        window.location.reload(true);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    Revok signature !
                  </button>
                </div>
              );
            })}
          </div>
        </>
      )}
    </AdminStyle>
  );
}
const AdminStyle = styled.div`
  min-height: calc(var(--vh, 1vh) * 100);
  padding-top: 4.6rem;

  .sticky {
    position: fixed;
    width: 100%;
    background: white;
  }

  .main-title {
    /* background: salmon; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    gap: 1rem;

    button {
      all: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      /* padding: 0.5rem; */
      background: var(--primary);
      color: white;
      /* height: 30px;
      width: 30px; */
      padding: 1rem;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  .option-list {
    border: 1px solid grey;
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 1rem;

    .left,
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
    }
  }
  .grid {
    margin-top: 11rem;
    display: grid;
    grid-template-columns: 1fr;
    border: 2px solid lightblue;

    .flex-container {
      padding: 0.15rem 0;
      display: grid;
      justify-items: center;
      align-items: center;

      grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr;

      /* padding: 0.75rem 0;
      max-width: 1200px;
      display: flex;

      justify-content: space-between;
      align-items: center; */
    }
  }
`;
